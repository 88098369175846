import React from "react"
import Header from "../common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import Srieed from "../Projects/Srieed"
import About from "../about/About"
import Pricing from "../pricing/Pricing"
import Blog from "../blog/Blog"
import Services from "../services/Services"
import Contact from "../contact/Contact"
import News from "../news/News"
import Executive from "../team/Executive/Executive"
import Staff from "../team/staff/Staff"
import Trustee from "../team/trustee/Trustee"
import Mission from "../mission/Mission"
import Historytl from "../aboutus/history/Historytl"
import Projects from "../about/project/Projects"
import Publications from "../news/publications/Publications"
import Signin from "../news/newsUploads/Signin"
import ServicePage from "../services/service/serviceComponects/ServicePage"
import ScrollToTop from "../ScrollTop"
import Farmgate from "../news/publications/singlepiblications/Farmgate"
import AnnualReport from "../news/publications/singlepiblications/AnnualReport"


const Pages = () => {
  return (
    <>
      <Router>
      <ScrollToTop/>
        
        <Switch>
         
          <Route exact path='/' component={Home} />
          
          <Route exact path='/mission' component={Mission} />
          <Route exact path='/projects' component={Projects} />
          <Route exact path='/historytimeline' component={Historytl} />
          <Route exact path='/executive' component={Executive} />
          <Route exact path='/staff' component={Staff} />
          <Route exact path='/trustee' component={Trustee} />
          <Route exact path='/pricing' component={Pricing} />
          <Route exact path='/about' component={About} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/news' component={News}/>
          <Route exact path='/publications' component={Publications} />
          <Route exact path='/signin' component={Signin} />
          <Route exact path='/servicepage' component={ServicePage} />

          <Route exact path='/projects/srieed' component={Srieed} />
          <Route exact path='/news/farmgate' component={Farmgate} />
          <Route exact path='/news/AnnualReport' component={AnnualReport}/>
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default Pages
