import shippingIcon from "../../assets/free-shiping-icon.svg";
import paymentIcon from "../../assets/quick-payment-icon.svg";
import supportIcon from "../../assets/support-icon.svg";
import productOneIcon from "../../assets/product-one.svg";
import productTwoIcon from "../../assets/produc-two.svg";
import productThreeIcon from "../../assets/product-three.svg";
import plantIcon from "../../assets/plant-icon.svg";
import sunIcon from "../../assets/sun-icon.svg";
import waterIcon from "../../assets/water-icon.svg";
import temperatureIcon from "../../assets/temperature-icon.svg";



export const nav = [
  {
    text: "Home",
    path: "/",
  },
  {
    text: "About FRT",
    path: "/about",
  },
  {
    text: "Our Work & Services",
    path: "/services",
  },
  {
    text: "News & Media",
    path: "/blog",
  },
  {
    text: "Why Radio",
    path: "/pricing",
  },
  {
    text: "Contact US",
    path: "/contact",
  },
]
export const referenceTitle = "Interior Plants Reference";
export const referenceSubtitle = "make your home cozy with refreshing plants";

export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Accountability",
    total: "",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Farmer Centeredness",
    total: "",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Innovativeness",
    total: "",
  },
  {
    cover: "../images/hero/h4.png",
    name: "MultiMedia Approach",
    total: "",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Productiveness",
    total: "",
  },{
    cover: "../images/hero/h6.png",
    name: "Productiveness",
    total: "",
  },

]
export const services = [
  {
    cover: "../images/hero/callcenter.png",
    link:'/servicepage',
    name: "CALL CENTER (MLIMI HOTLINE)",
    total: "A direct response information service dedicated to help farmers with imediate support, by answering their questions, giving them advices and many more.",
  },
  {
    cover: "../images/hero/radio.png",
    link:'/servicepage',
    name: "RADIO PROGRAM ",
    total: "A radio Program that educates, informs and equip farmers with required knowledge about sustainable agricultural practices.",
  },
  {
    cover: "../images/hero/sms.png",
    link:'/servicepage',
    name: "SMS PUSH",
    total: "A service that allows farmers to request for a service by sending an SMS to our servers",
  },
  {
    cover: "../images/hero/opensms.png",
    link:'/servicepage',
    name: "SMS PULL",
    total: "A service that allows farmers to request for a service by sending an SMS to our servers",
  },
  {
    cover: "../images/hero/call.png",
    link:'/servicepage',
    name: "BEEP4INFO",
    total: "This is an alert service that farmers use to notify FRT of their involvement in a program or just request for information",
  },
  {
    cover: "../images/hero/mic.png",
    link:'/servicepage',
    name: "IVR SYSTEM",
    total: "Interactive Voice Recordings that allows a computer to interact with humans through the use of voice and DTMF tones input via a keypad",
  },

]

export const careTitle = "How to care for plants";
export const careSubtitle = "Take care of plants with all your heart";

export const careList = [
  {
    title: "Adjust Lighting",
    subtitle:
      "When caring for houseplants, make sure the room temperature is neither too cold nor too hot.",
    img: sunIcon,
  },
  {
    title: "Don't water too often",
    subtitle:
      "Watering ornamental houseplants does not have to be done every day.",
    img: waterIcon,
  },
  {
    title: "Choose the right container.",
    subtitle:
      "Houseplants will need different containers depending on their type and size.",
    img: plantIcon,
  },
  {
    title: "Fertilize regularly",
    subtitle:
      "The nutrients most indoor houseplants need are nitrogen for balance and potassium for stem strength.",
    img: temperatureIcon,
  },
];
export const list = [
  {
    id: 3,
    cover: "../images/list/p-1.png",

    ptext:"Alimi kusadziwa ndikufa komwe, dziwani za mitengo yovomelezeka yogulitsira zokolola zanu. Mafumu, alangizi, mabwalo aza ulangizi ndi udido wathu kulimbikitsa mitengoyi m'madera mwanthu…",
    name: "Farm Gate Prices",
    location: "Malawi ",
    category: "For Rent",
    price: "Read More",
    type: "Apartment",
  },
  {
    id: 2,
    cover: "../images/list/p-7.png",
    ptext:" by providing them a platform through which they are able to share their experiences, and to learn more either from their fellow farmers or from the extension workers.…",
    name: " Farm Radio Trust is supporting beef cattle Farmers in Mzimba district",
    location: "Mzimba, Malawi",
    category: "For Rent",
    price: "Read More",
    type: "Offices",
  },

  {
    
    id: 1,
    cover: "../images/list/p-2.png",
    ptext:"In response to the Tropical Cyclone Freddy disaster, members of staff for Farm Radio Trust, and it’s strategic partner Farm…",
    name: "FRT AND FRI RESPONSE TO CYCLONE FREDDY DISASTER",
    location: "Lilomgwe, Malawi",
    category: "For Sale",
    price: "Read More",
    type: "Condos",
  }
  
  ,

 



]
export const allProjects = [
  {
    id: 1,
    cover: "../images/list/iheard.jpg",
    ptext:"To contribute to dismantling barriers to sexual and reproductive health and rights (SRHR) for Adolescent Girls and Young Women (AGYW) and Adolescent Boys and Young Men (ABYM) in Malawi",
    name: "Innovation in Health, Rights and Development (iHEARD)",
    period: "Sep 2021 - Nov 2025",
    category: "For Sale",
    price: "Read More",
    type: "Condos",
    url:"/mission",
  }
  
  ,

  {
    id: 2,
    cover: "../images/list/iwcarp.jpg",

    ptext:"In the year under review, FRT  partnered the World Food Programme (WFP) to implement the ‘Interactive Weather and Climate Adaptation Radio Programming’ (IWCARP) Phase III ",
    name: "Interactive Weather and Climate Adaptation Radio Programming (IWCARP) III",
    period: "Dec 2021 - Nov 2022 ",
    category: "For Rent",
    price: "Read More",
    type: "Apartment",
    url:"/projects",
  },
  
   {
    id: 3,
    cover: "../images/list/nutrition.jpg",

    ptext:"the project aims at raising awareness of the target group on nutrition especially on breastfeeding, dietary diversification, adolescent nutrition, early stimulation, comprehensive feeding and Water Sanitation and Hygiene (WASH).",
    name: "Nutrition-Digital Social and Behavior Change Communication (SBCC) ",
    period: "April 2022 - Dec 2022",
    category: "For Rent",
    price: "Read More",
    type: "Apartment",
    url:"/historytimeline",
  },
  {
    id: 4,
    cover: "../images/list/srieed2.jpg",
    ptext:"The project aims at increasing awareness, knowledge and skills of adult and youth male and female SHFs in agriculture extension through provision of comprehensive information on Agriculture extension and advisory services",
    name: "Scaling up Radio and ICTs in Enhancing Extension Delivery (SRIEED II)",
    period: "August 2020 - July 2025",
    category: "For Rent",
    price: "Read More",
    type: "Offices",
    url:"/projects/srieed",

  },
  {
    id: 5,
    cover: "../images/list/trade.jpg",
    ptext:"FRT upscaled the promotion of Good Agriculture Practices (GAP) in groundnuts and soya in Mchinji, Lilongwe and Ntchisi, so as to contribute towards improved sustainable livelihoods of rural people in Malawi.",
    name: "Communication 4 Scale: Good Agricultural Practices (G/nuts ad Soya) (Trade Project)",
    period: "Dec 2022 - Mar 2023",
    category: "For Sale",
    price: "Read More",
    type: "Condos",
    url:"/mission",
  }
  
  ,

  {
    id: 6,
    cover: "../images/list/viamo.jpg",

    ptext:"FRT partnered VIAMO and the GIZ, to facilitate the remote dissemination of agricultural key messages and training through Interactive Voice Response (IVR), SMS and audio recordings, in ten (10) districts",
    name: "Remote dissemination of agricultural key messages & training through IVR, SMS and audio recordings ",
    period: "Oct 2021 - July 2023",
    category: "For Rent",
    price: "Read More",
    type: "Apartment",
    url:"/mission",
  }
  ,

  {
    id: 7,
    cover: "../images/list/viamo.jpg",

    ptext:"Farm Radio Trust used its digital extension and advisory services platforms to increase awareness among the general public in Malawi about the importance of aquaculture and good aquaculture practices in Malawi.",
    name: "AVCP",
    period: "Jan 2022 - Dec 2022 ",
    category: "For Rent",
    price: "Read More",
    type: "Apartment",
  }


]
export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "1500",
    name: "ICT Hubs",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "28",
    name: "Districts of Engegement",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "2,500",
    name: "Hotline Monthly Cases",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "35",
    name: "Radio Station Partners",
  },
]
export const location = [
  {
    id: 1,
    name: "Government Of Flanders",
    cover: "./images/location/flanders.jpg",
  },
  {
    id: 2,
    name: "WFP",

    cover: "./images/location/wfp.jpg",
  },
  {
    id: 3,
    name: "AGRA",
    cover: "./images/location/agra.jpg",
  },
  {
    id: 4,
    name: "World University Service of Canada",
    cover: "./images/location/wuc.png",
  },
  {
    id: 5,
    name: "GIZ",
    cover: "./images/location/giz.jpg",
  },  {
    id: 6,
    name: "ecosia",
    cover: "./images/location/ecosia.png",
  },  {
    id: 7,
    name: "CADI",
    cover: "./images/location/cadi.jpg",
  },  {
    id: 8,
    name: "Nkhotakota",
    cover: "./images/location/nkotakota.jpg",
  },  {
    id: 9,
    name: "zodiak",
    cover: "./images/location/zodiak.jpg",
  },
  {
    id: 10,
    name: "World_Resources_Institute_logo",
    cover: "./images/location/World_Resources_Institute_logo.jpg",
  }, 
   {
    id: 11,
    name: "World_Resources_Institute_logo",
    cover: "./images/location/osisa.jpg",
  },
    
]
export const team = [
  {

    cover: "../images/customer/team-1.jpg",
    posation: "Chief Executive Officer(CEO)",
    name: "George Vilili",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/team-2.jpg",
    posation: " Director of Programs (DoP)",
    name: "Flora Khomani",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/team-3.jpg",
    posation: "Director of Finance & Administration",
    name: "Mercius Mbewe",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },

]

export const admin = [
  {

    cover: "../images/customer/brenda.png",
    posation: "Finance Officer",
    name: "Brenda Nkata",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/edward.png",
    posation: "Finance Assistant",
    name: "Edward Mathuwa",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/anna.png",
    posation: "Administration Assistance",
    name: "Anastanzia Mdoko",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },  {

    cover: "../images/customer/alinafe.png",
    posation: "Office Assistant",
    name: "Alinafe Kachere",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },  {

    cover: "../images/customer/twama.png",
    posation: "Bussiness Developments Officers",
    name: "Twamanya Simwaka Dube",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
 
 
 

]
export const ictteam = [
  {

    cover: "../images/customer/allen.png",
    posation: "ICT Officer",
    name: "Allen Maneya",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/01.png",
    posation: "ICT Officer",
    name: "Emmanuel Mtambo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  
 
 

]
export const radio = [
  {

    cover: "../images/customer/yanja.png",
    posation: "Head of Radio Programming",
    name: "Yanjanani Dannayo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/glad.png",
    posation: "Radio Producer/Presenter",
    name: "Gladys Phiri",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/john.png",
    posation: " Radio Producer/Presenter",
    name: "John Mpakani",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  
  
 
 
 

]
export const content = [
  {

    cover: "../images/customer/tk.png",
    posation: "Call Center Supervisor",
    name: "Takondwa Chindiwo Kembo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/desire.png",
    posation: "E-Extension Officer",
    name: "Desire Nyondo ",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/fred.png",
    posation: "E-Extension Officer",
    name: "Fred Saulos",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  
  
 
 
 

]
export const transport = [
  {

    cover: "../images/customer/dag.png",
    posation: "Head Driver",
    name: "Douglas Masyano ",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/prince.png",
    posation: "Driver",
    name: "Prince Imanah ",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },

  
  
 
 
 

]
export const programs = [
  {

    cover: "../images/customer/aug.png",
    posation: "Programs Officer",
    name: "Augustine Mulomole ",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/01.png",
    posation: "Programs Officer",
    name: "Arnold Chimtengo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {

    cover: "../images/customer/clement.png",
    posation: "Programs Officer",
    name: "Clement Shema",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
 
 
 

]
export const teamicon = [
  {
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },]
export const price = [
  {
    title: "Our Vision",
    button:"Read More",
    price: "29",
    ptext: "World that has sustainable livelihoods of farming communities and contribute to the develoment of the Nation ",
    cover: "../images/hero/about1.png",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    button:"Get Started",
    title: "What Is FRT",
    price: "49",
    cover: "../images/hero/about4.png",
    ptext: "Farm Radio Trust (FRT) is a leading non- governmental organization dedicated exclusively to serving smallholder communities through the provision of ICT based information, communication, extension and advisory services in Malawi with a growing reputation and involvement in program and policy development processes across sub-Saharan Africa",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    title: "Our Mission",
    button:"Read More",
    price: "79",
    cover: "../images/hero/about3.png",
    ptext: "To be a center of excellence for ICT based extension and advisory services through transformative programming, capacity building, advocacy and partnerships founded on research and knowledge management”.",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "All PAGES",
    text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Service Page" }, { list: "Contact Page" }, { list: "News & Media" }],
  },
  {
    title: "ALL SECTIONS",
    text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }],
  },
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Blog" }, { list: "Login" }, { list: "Changelog" }],
  },
]
